import { useState } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import ListGroup from 'react-bootstrap/ListGroup';
import ListGroupItem from 'react-bootstrap/ListGroupItem';
import Modal from 'react-bootstrap/Modal';
import { FaExpand } from 'react-icons/fa';
import { getUniqueElementId } from '../lib/helpers';
import { FreezeValue, WhenDefined } from './FX';
import { JSONInspect } from './JSONInspect';
import { SectionCard } from './SectionCard';
import {Docs} from "../lib/splytech-router-types";


export function RequestExamples(props: {
  examples: Docs.RequestExample[]
}) {
  const [showExampleNumber, setShowExampleNumber] = useState<number>();

  return (
    <>
      <SectionCard caption="Request Examples" content={() =>
        <ListGroup variant={'flush'}>
          {props.examples.map((item, index) =>
            <ListGroupItem key={getUniqueElementId(item)}
                           className="d-flex justify-content-between align-items-center clickable"
                           onClick={() => setShowExampleNumber(index)}
            >
              {item.title}
              <FaExpand/>
            </ListGroupItem>,
          )}
        </ListGroup>
      }/>

      <Modal
        show={showExampleNumber !== undefined}
        size="lg"
        centered
        onHide={() => setShowExampleNumber(undefined)}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Request Example
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FreezeValue value={showExampleNumber} fn={(showExampleNumber) =>
            <WhenDefined value={showExampleNumber} then={(showExampleNumber) =>
              <Tabs defaultActiveKey={showExampleNumber}>
                {props.examples.map((item, index) =>
                  <Tab eventKey={index} title={item.title}>
                    {item.description && <p className="mt-3">{item.description}</p>}

                    {/*<pre className="mb-0 code-mirror-bg br-0"><code>POST {item.url}</code></pre>*/}
                    <JSONInspect title={`POST ${item.url}`} json={item.json}/>
                  </Tab>,
                )}
              </Tabs>
            }/>
          }/>
        </Modal.Body>
      </Modal>
    </>
  );
}
