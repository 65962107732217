import { ReactElement, useEffect, useState } from 'react';
import styled from 'styled-components';
import {Docs} from "../lib/splytech-router-types";

export function DocumentationLoader(props: {
  url?: string;
  preloadedDocs: Docs.Documentation | undefined,
  onLoaded: (docs: Docs.Documentation) => ReactElement;
}) {
  const [docs, setDocs] = useState<Docs.Documentation | undefined>(props.preloadedDocs);
  const loader = <img src='/assets/loader.gif' alt='loader'/>;

  useEffect(() => {
    if (props.preloadedDocs || !props.url) {
      return;
    }

    fetch(props.url).then(async (r) => {
      setDocs(await r.json());
    }).catch((e) => {
      console.log(e);
    });
  }, [
    props.url,
    props.preloadedDocs,
  ]);

  return (
    <>{docs ? props.onLoaded(docs) : <LoaderContainer>{loader}</LoaderContainer>}</>
  );
}

const LoaderContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
