import React, { ReactElement, useEffect, useState } from 'react';
import { Dropdown, FormControl, FormGroup, InputGroup } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import { DiGo } from 'react-icons/di';
import { FaNodeJs, FaPython, FaTerminal } from 'react-icons/fa';
import styled from 'styled-components';
import { t, use } from '../lib/fx';
import { normalizeRouterPath } from '../lib/helpers';
import { MyStorage } from '../lib/my-storage';
import { CodePreview } from './CodePreview';
import { CopyButton } from './CopyButton';
import { Use, WhenDefined, WhenTruthy } from './FX';
import { JSONInspect } from './JSONInspect';
import { StatusCode } from './StatusCode';
import {Docs} from "../lib/splytech-router-types";

type SupportedLanguages = 'shell' | 'node' | 'python' | 'go';
type ShellFlavours = 'bash' | 'fish';
type NodeFlavours = 'fetch' | 'http';
type PythonFlavours = 'requests';
type GoFlavours = 'native';


const Storage = {
  accessToken: MyStorage.create('TryMe.accessToken'),
  language: MyStorage.create('TryMe.language'),
  flavour: MyStorage.create('TryMe.flavour'),
};

const supportedLanguages: Array<{ id: SupportedLanguages; title: string; icon: ReactElement; flavours: string[] }> = [
  {
    id: 'shell',
    title: 'Shell',
    icon: <FaTerminal/>,
    flavours: ['bash', 'fish'],
  },
  {
    id: 'node',
    title: 'Node.js',
    icon: <FaNodeJs/>,
    flavours: ['fetch', 'http'],
  },
  {
    id: 'python',
    title: 'Python',
    icon: <FaPython/>,
    flavours: ['requests'],
  },
  {
    id: 'go',
    title: 'Go',
    icon: <DiGo/>,
    flavours: ['native'],
  },
];

type Response = {
  statusCode: number | null;
  headers: Record<string, string>;
  json: object;
};

const responseStorage = MyStorage.create<Response>(`TryMe.response`);

export function TryMe({
  requestPayload,
  endpoint,
  requestParams,
  requestQuery,
  baseUrl,
}: {
  endpoint: Docs.Endpoint | Docs.WebhookEndpoint;
  requestPayload: object;
  requestParams: Record<string, string>;
  requestQuery: Record<string, string | number>;
  baseUrl: string;
}) {
  const endpointId = `${endpoint.method}.${endpoint.path}`;
  const [accessToken, setAccessToken] = useState(() => Storage.accessToken.read(''));
  const [selectedLanguage, setSelectedLanguage] = useState(() => Storage.language.read(supportedLanguages[0].id));
  const [flavour, setFlavour] = useState(() => Storage.flavour.read(supportedLanguages[0].flavours[0]));
  const [response, setResponse] = useState<Response | undefined>(responseStorage.suffix(endpointId).read());

  const requestURL = formatURL(baseUrl, endpoint.path, requestParams, requestQuery);

  const headers = {
    'Content-Type': 'application/json',
    ...(accessToken ? { 'Authorization': `Bearer ${accessToken}` } : {}),
  };
  const requestExample = generateRequestExample(endpoint.method, requestURL, headers, requestPayload);

  useEffect(() => {
    Storage.language.write(selectedLanguage);
    Storage.accessToken.write(accessToken);
    Storage.flavour.write(flavour);
  }, [selectedLanguage, accessToken, flavour]);

  useEffect(() => {
    const allFlavours = supportedLanguages.find((item) => item.id === selectedLanguage)?.flavours;

    if (!allFlavours || allFlavours.some((item) => item === flavour)) {
      return;
    }

    setFlavour(allFlavours[0]);
  }, [selectedLanguage, flavour]);

  useEffect(() => {
    setResponse(responseStorage.suffix(endpointId).read());
  }, [endpointId]);

  return (
    <Component>
      <section>
        <FormGroup className={'mb-3 overflow-scroll'}>
          <h6 className="form-label">Language</h6>


          <div className={'gap-2 d-flex language-selector'}>
            {supportedLanguages.map((lang) =>
              <div key={lang.id}>
                <Button
                  variant={'outline-secondary'}
                  active={lang.id === selectedLanguage}
                  onClick={() => setSelectedLanguage(lang.id)}
                >
                  <div className={'fs-4'}>{lang.icon}</div>
                  <small>{lang.title}</small>
                </Button>
                {/*<WhenTruthy value={selectedLanguage === lang.id && lang.flavours.length > 1} then={() =>*/}
                {/*  <FormSelect className={'mt-2'} value={flavour} onChange={targetValue(setFlavour)}>*/}
                {/*    {lang.flavours.map((flavour) =>*/}
                {/*      <option>{flavour}</option>,*/}
                {/*    )}*/}
                {/*  </FormSelect>*/}
                {/*}/>*/}
              </div>,
            )}

            {/*<div className="vr mx-3"/>*/}
            {/*<Button variant={'outline-secondary'} active>*/}
            {/*  {flavour}*/}
            {/*</Button>*/}
          </div>

        </FormGroup>

        {/*{supportedLanguages.map((supportedLanguage) =>*/}
        {/*    supportedLanguage.id === selectedLanguage && whenTruthy(supportedLanguage.flavours.length > 1, () =>*/}
        {/*      <FormGroup className={'mb-3'}>*/}
        {/*        <label className={'form-label'}>Flavour</label>*/}
        {/*        <FormSelect value={flavour} onChange={targetValue(setFlavour)}>*/}
        {/*          {supportedLanguage.flavours.map((flavour) =>*/}
        {/*            <option>{flavour}</option>,*/}
        {/*          )}*/}
        {/*        </FormSelect>*/}
        {/*      </FormGroup>,*/}
        {/*    ),*/}
        {/*)}*/}

        <FormGroup className={'mb-3'}>
          <h6 className="form-label">Authentication</h6>
          <InputGroup>
            <InputGroup.Text>Bearer</InputGroup.Text>
            <FormControl placeholder={'token'}
                         value={accessToken}
                         onChange={(evt) => setAccessToken(evt.target.value)}/>
          </InputGroup>
        </FormGroup>

        <Use value={requestExample[selectedLanguage]?.[flavour]} fn={(text) => <>
          <div className="code-mirror-bg d-flex px-3 py-2 rounded-top border-bottom border-secondary">
            <WhenTruthy
              value={supportedLanguages.find((item) => item.id === selectedLanguage)?.flavours}
              then={(flavours) =>
                <WhenTruthy
                  value={flavours.length > 1}
                  then={() =>
                    // <FormGroup>
                    //   <FormSelect value={flavour} onChange={targetValue(setFlavour)} className={'form-select-sm'}>
                    //     {flavours.map((flavour) =>
                    //       <option>{flavour}</option>,
                    //     )}
                    //   </FormSelect>
                    // </FormGroup>
                    <Dropdown>
                      <Dropdown.Toggle variant="secondary" id="dropdown-basic" size={'sm'}>
                        <span className={'pe-2 ps-1'}>{flavour}</span>
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        {flavours.map((flavour) =>
                          <Dropdown.Item
                            key={flavour}
                            onClick={() => setFlavour(flavour)}
                          >
                            {flavour}
                          </Dropdown.Item>,
                        )}
                      </Dropdown.Menu>
                    </Dropdown>
                  }/>
              }/>

            <CopyButton text={text} className={'ms-auto'} noBorder={true}/>
          </div>
          <CodePreview text={text} lang={selectedLanguage}/>
          <div className="code-mirror-bg d-flex px-3 py-3 rounded-bottom border-top border-secondary mb-3">
            <Button size={'sm'} className={'ms-auto px-3'} onClick={handleSend}>
              Send
            </Button>
          </div>
        </>}/>

        <WhenDefined value={response} then={(response) =>
          <>
            <div
              className="code-mirror-bg d-flex px-3 py-2 rounded-top border-bottom border-secondary d-flex justify-content-between align-items-center">
              <h6 className={'mb-0'}>Response</h6>
              {response.statusCode && <StatusCode statusCode={response.statusCode}/>}
            </div>
            <JSONInspect json={response.json}/>
            <div className="code-mirror-bg d-flex px-3 py-3 rounded-bottom border-top border-secondary mb-3">
            </div>
            {/*<CodePreview text={JSON.stringify(response.json, null, 2)} lang={'json'}/>*/}
          </>
        }/>
      </section>
    </Component>
  );

  function handleSend() {
    fetch(requestURL, {
      method: endpoint.method,
      headers,
      body: endpoint.method !== 'GET' ? JSON.stringify(requestPayload) : undefined,
    }).then(async (r) => {
      const json = await r.json();

      const response = {
        statusCode: r.status,
        headers: Object.fromEntries(r.headers.entries()),
        json,
      };
      responseStorage.suffix(endpointId).write(response);
      setResponse(response);
    }).catch((e) => {
      setResponse({
        statusCode: null,
        headers: {},
        json: e.message,
      });
    });
  }
}

const Component = styled.div`
  padding-bottom: 10%;

  .language-selector {
    button {
      border: 1px solid transparent;
      width: 90px;
      color: #9B9B9B;

      &.active, &:hover {
        background-color: unset;
        border: 1px solid #000;
        color: #000;
      }
    }
  }
`;


function formatURL(
  baseUrl: string,
  path: string,
  pathParams: Record<string, string>,
  search: Record<string, string | number>,
): string {
  const newPath = normalizeRouterPath(path)
    .replace(/\[([^:/]+)\]/g, (m, a) => `${pathParams[a] ?? m}`);

  const query = use(Object.entries(search), (query) => {
    const queryString = query
      .filter(([, value]) => value)
      .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
      .join('&');

    return queryString
      ? `?${queryString}`
      : '';
  });

  return baseUrl + newPath + query;
}


function generateRequestExample(
  method: string,
  url: string,
  headers: Record<string, string>,
  json: object | undefined,
): Record<SupportedLanguages, Record<string, string>> {
  const shouldPostData = !!json && ['POST', 'PATCH', 'PUT'].includes(method);

  const cleanup = (lines: Array<string | undefined>): Array<string> => lines
    .filter((item): item is string => item !== undefined);

  const formatShell = (lines: Array<string | undefined>): string => cleanup(lines)
    .join(' \\\n    ');

  const format = (lines: Array<string | undefined>): string => cleanup(lines).join('\n');
  const indent = (lines: string[], by: number) => lines.map((line, index) =>
    index ? `${' '.repeat(by)}${line}` : line,
  );
  const jsonStringify = (data: object, indentBy: number): string =>
    format(indent(JSON.stringify(data, null, 2).split('\n'), indentBy))
      // .replace(/"/g, '\'')
      .replace(/(['"a-z0-9])\n/gi, '$1,\n');

  return {
    shell: t<Record<ShellFlavours, string>>({
      bash: formatShell([
        `curl -v`,
        `--request ${method}`,
        `--url '${url}'`,
        ...Object.entries(headers).map(([key, value]) => `--header '${key}: ${value}'`),
        shouldPostData ? `--data @- << EOF\n${JSON.stringify(json, null, 2)}\nEOF` : undefined,
      ]),
      fish: formatShell([
        `curl -v`,
        `--request ${method}`,
        `--url '${url}'`,
        ...Object.entries(headers).map(([key, value]) => `--header '${key}: ${value}'`),
        shouldPostData ? `--data '\n${JSON.stringify(json, null, 2)}\n'` : undefined,
      ]),
    }),
    node: t<Record<NodeFlavours, string>>({
      fetch: format([
        `const fetch = require("node-fetch");`,
        '',
        `const url = '${url}';`,
        `const options = {`,
        `  method: "${method}",`,
        `  headers: ${jsonStringify(headers, 2)},`,
        shouldPostData ? `  body: JSON.stringify(${jsonStringify(json, 2)}),` : undefined,
        `};`,
        ``,
        `fetch(url, options)`,
        `  .then((response) => response.json())`,
        `  .then(console.log, console.error)`,
      ]),
      http: format([
        `const http = require("https");\n`,
        `const url = new URL("${url}");`,
        `const options = {`,
        `  method: "POST",`,
        `  headers: ${jsonStringify(headers, 2)},`,
        `};\n`,
        `const req = http.request(url, options, (res) => {`,
        `  const chunks = [];`,
        ``,
        `  res.on("data", (chunk) => {`,
        `    chunks.push(chunk);`,
        `  });`,
        ``,
        `  res.on("end", () => {`,
        `    const body = Buffer.concat(chunks);`,
        `    console.log(body.toString());`,
        `  });`,
        `});\n`,
        shouldPostData ? `req.write(JSON.stringify(${jsonStringify(json, 0)}))` : undefined,
        `req.end();`,
      ]),
    }),
    python: t<Record<PythonFlavours, string>>({
      requests: format([
        `import requests\n`,
        `url = "${url}"`,
        `headers = ${jsonStringify(headers, 0)}`,
        shouldPostData ? `json = ${jsonStringify(json, 0)}` : undefined,
        '',
        `response = requests.request("${method}", url, ${shouldPostData ? 'json=json, ' : ''}headers=headers)`,
        `print(response)`,
      ]),
    }),
    go: t<Record<GoFlavours, string>>({
      native: format([
        `package main\n`,
        `import (`,
        `  "fmt"`,
        `  "strings"`,
        `  "net/http"`,
        `  "io/ioutil"`,
        `)\n`,
        `func main() {`,
        `  url := "${url}"`,
        shouldPostData ? `  json := strings.NewReader(${JSON.stringify(JSON.stringify(json))})` : undefined,
        '',
        `  request, _ := http.NewRequest("${method}", url${shouldPostData ? ', json' : ''})\n`,
        ...Object.entries(headers).map(([key, value]) =>
          `  request.Header.Add("${key}", "${value}")`,
        ),
        ``,
        `  response, _ := http.DefaultClient.Do(request)\n`,
        `  defer response.Body.Close()`,
        `  responseBody, _ := ioutil.ReadAll(response.Body)\n`,
        `  fmt.Println(res)`,
        `  fmt.Println(string(responseBody))`,
        `}`,
      ]),
    }),
  };
}
