import styled from 'styled-components';
import { PageSections } from '../Components/PageSections';
import {Docs} from "../lib/splytech-router-types";

export function FreeformPage(props: {
  page: Docs.Page;
  variables?: Record<string, string>;
}) {
  return (
    <Section className="px-3">
      <h2>{props.page.title}</h2>
      <PageSections items={props.page.sections} variables={props.variables}/>
    </Section>
  );
}

const Section = styled.section`
  padding-bottom: 10% !important;
`;
