import { MouseEventHandler, ReactElement, useEffect, useState } from 'react';
import { FormCheck, ListGroup } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import FormControl from 'react-bootstrap/FormControl';
import FormSelect from 'react-bootstrap/FormSelect';
import ListGroupItem from 'react-bootstrap/ListGroupItem';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import Stack from 'react-bootstrap/Stack';
import { FaChevronDown, FaChevronUp, FaPlus } from 'react-icons/fa';
import styled from 'styled-components';
import { switchOn, whenDefined } from '../lib/fx';
import { getType, getUniqueElementId, targetValue } from '../lib/helpers';
import { classNames } from '../lib/utils';
import { SwitchOn, Use, WhenDefined, WhenTruthy } from './FX';
import { Markdown } from './Markdown';
import { PropertyFieldChildrenElements } from './PropertyFieldChildrenElements';
import { StyledText } from './StyledText';
import {Docs} from "../lib/splytech-router-types";

export function PropertyField({
  name,
  spec,
  userValue,
  onSet,
}: {
  name: string;
  spec: Docs.TElement;
  userValue: any;
  onSet: (value: any) => void,
}) {
  const isExpandable = (getType(spec) === 'object' && !!(spec as Docs.TObject).keys);
  const isAddable = ['array', 'array[object]', 'array[alternatives]'].includes(getType(spec));
  const [expanded, setExpanded] = useState(userValue !== undefined && isExpandable);
  const [input] = useState('');
  const [inputTouched, setInputTouched] = useState(false);
  const [childrenElements, setChildrenElements] = useState<Array<object>>(userValue !== undefined && isAddable ? userValue : undefined);
  const [alternative, setAlternative] = useState<number>();

  const selectedAlternative = () => spec.matches?.[alternative as any];
  const defaultValue = () => spec.flags?.default ?? '';
  const isInputValidObjectID = () => !inputTouched || /^[0-9a-f]{24}$/i.test(input);
  const inputTouchedHandler = () => input ? setInputTouched(true) : void 0;

  const toNumber = (setter: (value: number) => void) => {
    return (value: string): void => {
      setter(+value);
    };
  };

  const handleOnItemClick: MouseEventHandler = (evt) => {
    evt.stopPropagation();

    if (!isExpandable) {
      return;
    }

    const newValue = !expanded;

    setExpanded(newValue);
    onSet(newValue ? {} : undefined);
  };

  const ruleField = (rule: Docs.Rule): ReactElement => {
    switch (rule.name) {
      case 'length':
      case 'min':
      case 'max':
        return (<><strong>{rule.name}</strong>: {rule.args.limit}</>);
      case 'pattern':
        return (<><strong>{rule.name}</strong>: {rule.args.regex}</>);
    }

    return (<><strong>{(rule as any).name}</strong></>);
  };

  useEffect(() => {
    if (childrenElements && childrenElements !== userValue) {
      onSet(childrenElements);
    }
  }, [childrenElements, onSet, userValue]);

  return (
    <Container>
      <Stack gap={2} direction="horizontal" onClick={handleOnItemClick} className={classNames({
        clickable: isExpandable,
        clicked: expanded,
      })}>
        <div>
          <strong>
            <code className="field-name">{name}</code>
          </strong>
          <small className="text-muted ms-1">{getType(spec)}</small>
          <WhenTruthy value={spec.flags?.presence !== 'optional'} then={() =>
            <small className="text-danger ms-1">required</small>
          }/>
          <WhenTruthy value={spec.flags?.description} then={(description) =>
            <StyledText>
              <Markdown className="field-description" content={description}/>
            </StyledText>
          }/>
        </div>
        <div className="ms-auto">
          <OverlayTrigger trigger="focus" placement="bottom" overlay={
            whenDefined(spec.rules, (rules) =>
              <Popover id="popover-contained">
                <Popover.Body>
                  <ul className="list-unstyled m-0">
                    {rules.map((rule) =>
                      <li key={rule.name}>
                        {ruleField(rule)}
                      </li>,
                    )}
                  </ul>
                </Popover.Body>
                <Popover.Header>
                  <WhenDefined value={spec.flags?.default} then={(value) =>
                    <span><strong>Default:</strong> {value}</span>
                  }/>
                </Popover.Header>
              </Popover>,
            ) ?? <></>
          }>
            {switchOn(getType(spec), {
              'boolean': () => <FormCheck
                onChange={(evt) => onSet(evt.target.checked ?? undefined)}
              />,
              'string': () => spec.flags?.only && spec.allow
                ? <FormSelect className="my-input"
                              value={userValue ?? ''}
                              onChange={(evt) => onSet(evt.target.value)}
                >
                  <option value=""/>
                  {spec.allow.map((item) =>
                    <option key={item}>{item}</option>,
                  )}
                </FormSelect>
                : <FormControl className="my-input"
                               value={userValue ?? ''}
                               placeholder={`${defaultValue()}`}
                               readOnly={spec.flags?.only}
                               onChange={(evt) => onSet(evt.target.value)}
                />,
              'ObjectID': () => <FormControl isInvalid={!isInputValidObjectID()}
                                             value={userValue ?? ''}
                                             className={'my-input'}
                // onChange={targetValue(setInput)}
                                             onChange={(evt) => onSet(evt.target.value)}
                                             onBlur={inputTouchedHandler}
              />,
              'number': () => <FormControl className="my-input"
                                           type="number"
                                           value={userValue ?? ''}
                                           onChange={(evt) => onSet(+evt.target.value)}
                                           placeholder={`${defaultValue()}`}
              />,
              'date': () => <FormControl className="my-input"
                                         type="datetime-local"
                                         value={userValue ? userValue.slice(0, 16) : ''}
                                         onChange={(evt) =>
                                           onSet(evt.target.value ? new Date(evt.target.value).toISOString() : '')
                                         }
                                         placeholder={`${defaultValue()}`}
              />,
              'alternatives': () =>
                <FormSelect className="my-input" onChange={targetValue(toNumber(setAlternative))}>
                  <option value=""/>
                  {spec.matches!.map((item, index) =>
                    <option key={getUniqueElementId(item)}
                            value={index}
                            onSelect={() => setAlternative(index)}
                    >
                      Alternative {index + 1}
                    </option>,
                  )}
                </FormSelect>
              ,
            }) ?? <></>}
          </OverlayTrigger>

          <WhenTruthy
            value={isExpandable}
            then={() =>
              <SwitchOn value={expanded} cases={{
                'true': () => <FaChevronUp className={'property-field-button'}/>,
                'false': () => <FaChevronDown className={'property-field-button'}/>,
              }}/>
            }
          />

          <WhenTruthy
            value={isAddable}
            then={() =>
              <button className="btn btn-sm btn-outline-secondary"
                      onClick={() => setChildrenElements([...(childrenElements ?? []), {}])}>
                <FaPlus/>
              </button>
            }
          />
        </div>
      </Stack>

      <WhenTruthy value={expanded} then={() =>

        <SwitchOn value={getType(spec)} cases={{
          'object': () =>
            <div className="mt-2">
              <Use value={spec as Docs.TObject} fn={(value) =>
                <ListGroup>
                  {Object.entries(value.keys ?? {}).map(([key, spec]) =>
                      <ListGroupItem key={key} variant={'secondary'}>
                        <PropertyField name={key} spec={spec} userValue={userValue?.[key]} onSet={(value) => {
                          onSet({
                            ...userValue,
                            [key]: value || undefined,
                          });
                        }}/>
                      </ListGroupItem>,
                  )}
                </ListGroup>
              }/>
            </div>,
        }}/>

      }/>

      <WhenDefined value={childrenElements} then={(childrenElements) =>
        <PropertyFieldChildrenElements
          childrenElements={childrenElements}
          spec={spec as Docs.TArray}
          setChildrenElements={(value) => {
            setChildrenElements(value);
            console.log('setChildrenElements', value)
          }}
        />
      }/>

      <WhenDefined value={selectedAlternative()} then={(selectedAlternative) =>
        <Card className="mt-2">
          <Use value={selectedAlternative.schema as Docs.TObject} fn={(value) =>
            Object.entries(value.keys ?? {}).map(([key, spec]) =>
              <ListGroupItem key={key} variant={'secondary'}>
                <PropertyField name={key} spec={spec} userValue={userValue?.[key]} onSet={(value) => onSet({
                  [key]: value || undefined,
                })}/>
              </ListGroupItem>,
            )
          }/>
        </Card>
      }/>
    </Container>
  );
}

const Container = styled.section`
`;
