import React, { useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.scss';
import { DocumentationLoader } from './Components/DocumentationLoader';
import { WhenTruthy } from './Components/FX';
import { getUniqueElementId } from './lib/helpers';
import { ApiReferencePage } from './Pages/ApiReferencePage';
import { Home } from './Pages/Home';
import {Docs} from "./lib/splytech-router-types";

export type APISpec = {
  id: string;
  baseUrl: string;
  specUrl?: string;
  spec?: Docs.Documentation;
  image: string;
  title: string;
  description?: string;
};

export type Config = {
  home: {
    header: string;
    intro: string;
  };
  apis: APISpec[];
};

function App() {
  const [config, setConfig] = useState<Config>();

  const loadConfig = (name: string): Promise<Config> =>
    fetch(`/config/${name}.json`)
      .then((r) => r.json())
      .then((r) => typeof r === 'string' ? loadConfig(r) : r);

  useEffect(() => {
    loadConfig(new URL(document.location.href).searchParams.get('host') ?? window.location.hostname).then(setConfig);
  }, []);

  return (
    <WhenTruthy value={config} then={(config) =>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home config={config}/>}/>

          <Route path="reference">
            {config.apis.map((item) =>
              <Route key={getUniqueElementId(item)}
                     path={`${item.id}`}
                     element={
                       <DocumentationLoader url={item.specUrl} preloadedDocs={item.spec} onLoaded={(docs) =>
                         <ApiReferencePage documentation={docs} baseUrl={item.baseUrl}/>
                       }/>
                     }
              />,
            )}
            {config.apis.map((item) =>
              <Route key={getUniqueElementId(item)}
                     path={`${item.id}/:page`}
                     element={
                       <DocumentationLoader url={item.specUrl} preloadedDocs={item.spec} onLoaded={(docs) =>
                         <ApiReferencePage documentation={docs} baseUrl={item.baseUrl}/>
                       }/>
                     }
              />,
            )}
          </Route>


        </Routes>
      </BrowserRouter>
    }/>
  );
}

export default App;
